<template>
   <div class="cont">
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>运营</el-breadcrumb-item>
            <el-breadcrumb-item>全员营销</el-breadcrumb-item>
            <el-breadcrumb-item>营销商品</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
            <!-- 查询框 -->
      <el-row class="content-box">
            <el-row class="search-box">
               <el-row class="search-row">
                  <el-row class="search-item">
                     <label>营销类型：</label>
                     <el-select v-model="type" clearable class="width-175" :placeholder="$t('msg.select')">
                        <el-option v-for="(item,index) in dictData['order-type']" :key="index" :label="item" :value="index"></el-option>
                     </el-select>
                  </el-row>
                  <el-row class="search-item">
                     <label>推广状态：</label>
                     <el-select v-model="state" clearable  class="width-175" :placeholder="$t('msg.select')">
                        <el-option

                              v-for="item in statelist"
                              :key="item.state"
                              :label="item.label"
                              :value="item.state">
                        </el-option>
                     </el-select>
                  </el-row>
                  <el-row class="search-item">
                     <label>商品名称：</label>
                     <el-input class="width-175 m-left-5"  v-model="productName"></el-input>
                  </el-row>
                  <el-button  type="primary" @click="getSeckillCoupons">搜索</el-button>
                  <el-button  @click="reset()">重置</el-button>
               </el-row>

            </el-row>
            <!-- 表格 -->
            <el-row class="table-box">
               <el-button class="add-btn bg-gradient"  type="primary" @click="addSeckillProduct">添加分销商品</el-button>
               <el-table
                     ref="mainTable"
                     border
                     :data="goodslist"
                     :stripe="true"
                     >
                  <el-table-column label="序号" width="60">
                     <template slot-scope="scope">
                        {{scope.$index + 1}}
                     </template>
                  </el-table-column>
                  <el-table-column prop="name" label="营销名称" min-width="100"  :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column prop="type" label="营销类型" min-width="100">
                     <template slot-scope="scope">
                        {{scope.row.type  | filterOrderType(that) }}
                     </template>

                  </el-table-column>
                  <el-table-column prop="productName" label="商品名称" min-width="150"  :show-overflow-tooltip="true">
                     <template slot-scope="scope">
                        <span v-if="scope.row.allSalesProduct == 'YES' ">所有{{ scope.row.type | goodsTypeState}}</span>
                        <span v-else>{{scope.row.productName }}</span>
                     </template>
                  </el-table-column>
                  <el-table-column prop="productPrice" label="商品单价" min-width="100"></el-table-column>
                  <el-table-column prop="seckillPrice" label="秒杀提佣" min-width="100">
                     <template slot-scope="scope">
                        <span>{{scope.row.isSeckillCommission == "YES"? "是":"否" }}</span>
                     </template>
                  </el-table-column>
                  <el-table-column label="奖励时段" min-width="190" >
                     <template slot-scope="scope">
                        <el-row v-if="scope.row.timeType === 'EVERY_DAY'">
                           每天
                        </el-row>
                        <div v-else>{{ scope.row.beginTime }}至{{ scope.row.endTime }}</div>

                     </template>
                  </el-table-column>
                  <el-table-column prop="dealQuantity" label="成交数量"  min-width="100">
                     <template slot-scope="scope">
                        {{scope.row.dealQuantity || 0}}
                     </template>
                  </el-table-column>
                  <el-table-column prop="awardAmount" label="奖励总金额" min-width="110" >
                     <template slot-scope="scope">
                        {{scope.row.awardAmount || 0}}
                     </template>
                  </el-table-column>
<!--                  <el-table-column prop="awardIntegral" label="奖励总积分" min-width="110" >-->
<!--                     <template slot-scope="scope">-->
<!--                        {{scope.row.awardIntegral || 0}}-->
<!--                     </template>-->
<!--                  </el-table-column>-->
                  <el-table-column prop="state" label="推广状态" min-width="100" >
                     <template slot-scope="scope">
                        {{ scope.row.state | filterStatus }}
                     </template>
                  </el-table-column>
                  <el-table-column :label="$t('msg.operating')" min-width="165"  fixed="right">
                     <template slot-scope="scope">

                        <el-button type="text" @click="editmarketingproduct(scope.row.id,scope.row.state)" >
                           <span v-if="scope.row.state == 'UNDER_PROMOTION'">停止推广</span>
                           <span v-else>开始推广</span>
                        </el-button>
                        <el-button type="text" @click="editSeckillProduct(scope.row.id,scope.row.state)" >
                           编辑
                        </el-button>
                        <el-button type="text" style="color: #F56C6C;" @click="delSeckillProduct(scope.row.id,scope.row.state)" >
                          删除
                        </el-button>

                     </template>
                  </el-table-column>
               </el-table>
               <!-- 分页 -->
               <pagination :total="total" :page-size="limit" @handleSizeChangeSub="couponPageNum" @handleCurrentChangeSub="couponCurrPage"/>
            </el-row>

      </el-row>


   </div>
</template>

<script>
import {urlObj,smtech} from '@/api/interface'
import { marketing } from '@/api/interface/smtech'
import {getDict} from '@/common/js/common' // 公共js
import { mapState } from "vuex"; // 引入子组件(分页)
export default {
   data(){
      return{
         that:this,
         activeName: 'first',   // tab栏
         state:'',//推广状态
         type:'',//营销类型
         productName:'',//商品名称
         total:0,//商品总数量
         limit:10,
         page:1,
         pageshow:true,
         goodslist:[],
         statelist:[
            {state:'NOT_PROMOTED',label:'未推广'},
            {state:'UNDER_PROMOTION',label:'推广中'},
            {state:'OVER',label:'已结束'}
         ]

      }
   },
  watch: {
    hotelInfo: {
      handler(newVal, oldVal) {
        if (newVal.id !== oldVal.id && oldVal) {
          this.hotelId = this.hotelInfo.id
          this.hotelCode = this.hotelInfo.hotelCode
          this.getSeckillCoupons()
        }
      }
    }
  },
   computed:{
      ...mapState(['hotelInfo','dictData'])
   },

   mounted() {
      getDict(['order-type'],true,)
      this.hotelId = sessionStorage.getItem('hotelId')
      this.getSeckillCoupons()
   },
   methods: {

      // 获取营销商品列表
      getSeckillCoupons(){
         const url = marketing.getmarketingproductslist + `?limit=${this.limit}&page=${this.page}`
         const param = {
            state:this.state,
            productName:this.productName,
            type:this.type,
            hotelId:this.hotelInfo.id
         }
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success) {
               this.total = res.total
               this.goodslist = res.records
               this.goodslist.forEach(item=>{
                  if (item.hasOwnProperty('beginTime')){
                     item.beginTime = item.beginTime.replace('T',' ')
                     item.beginTime = (item.beginTime).substring(0,10)
                  }
                  if (item.hasOwnProperty('endTime')){
                     item.endTime = item.endTime.replace('T',' ')
                     item.endTime = (item.endTime).substring(0,10)
                  }


               })
            }
         })
      },

      //修改分销商品
      editmarketingproduct(id,state) {
         let goodsstate
         if (state == "UNDER_PROMOTION")goodsstate = 'NOT_PROMOTED'
         if (state == "NOT_PROMOTED" || state == 'OVER')goodsstate = 'UNDER_PROMOTION'
         const url = marketing.editmarketingproduct
         const param = {
            id:id,
            state:goodsstate
         }
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success) {
               this.$message.success('修改成功')
               this.getSeckillCoupons()

            }
         })
      },

      //删除分销商品
      delSeckillProduct(id,state){
         if (state == 'UNDER_PROMOTION') {
            this.$message.error('活动正在进行，暂不能删除')
            return
         }
         this.$confirm('此操作将永久删除该营销商品, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
         }).then(() => {
            const url = marketing.deletelmarketproduct+id
            const param = {
            }
            this.$axios.get(url, param).then(res => {
               if (res.success) {
                  this.$message.success('删除成功')
                  this.getSeckillCoupons()
               }
            })
         }).catch(() => {
            this.$message({
               type: 'info',
               message: '已取消删除'
            });
         });


      },
     //重置
      reset(){
         this.state = ''
         this.type = ''
         this.productName = ''
         this.pageshow = false
         this.page = 1
         this.$nextTick(() => {
            this.pageshow = true
         })
         this.getSeckillCoupons()
      },
      // 改变每页数
      couponPageNum(num){
         this.limit = num
         this.getSeckillCoupons()
      },
      // 改变当前页
      couponCurrPage(num){
         this.page = num
         this.getSeckillCoupons()
      },
      // 新增秒杀预售券/商品
      addSeckillProduct(){
         this.$router.push({ path: '/marketing_products/add', query: { action: 'add' } })
      },
      // 编辑秒杀预售券/商品
      editSeckillProduct(id,state){
         if (state == 'UNDER_PROMOTION') {
            this.$message.error('活动正在进行，暂不能编辑')
            return
         }
         this.$router.push({ path: '/marketing_products/add', query: { action: 'edit',id:id } })


      },



   },
   filters: {
      filterStatus(val){
         switch (val) {
            case 'UNDER_PROMOTION':
               return val = '推广中'
            case 'NOT_PROMOTED':
               return val = '未推广'
            case 'OVER':
               return val = '已结束'
         }
      },
      goodsTypeState(val) {
         switch (val) {
            case 'HOTEL':
               return val = '房型'
            case 'GOODS':
               return val = '商品'
            case 'COUPON_PRESELL':
               return val = '预售券'
         }
      },
      filterOrderType(val,that){
         let dictData = sessionStorage.getItem('dictData');
         if (val && that.dictData['order-type']) return that.dictData['order-type'][val] || val
      },
   }
}
</script>

<style lang="scss" scoped>
.cont{
   .search-box{
      display: flex; padding: 10px 0 20px 0;
      .el-row{ margin: 0 10px 0 0 }
   }
   .table-box{
      .add-btn { margin-bottom: 10px; float: right }
      .member-info{
         display: flex;
         img { width: 60px; margin-right: 10px }
      }
   }
   .des{ color: red; margin-right: 5px }
}
/deep/ .el-table th.gutter{
   /*
   * 解决element-ui 表格篡位的问题 👇
   */
   display: table-cell!important;
}
/deep/ .el-table--enable-row-transition .el-table__body td{
   // text-align: center;
   border-bottom: 1px solid #EBEEF5;

}

</style>
